import React from "react";
import StoryCard from "./StoryCard";
import StickyBar from "./StickyBar";

const Compliance = () => {
    let data1 =
        "RuneDukes, the core NFT collection incubated by Funs.AI, builds on Bitcoin Network, utilizing the Rune Protocol for next-gen NFTs. It represents a fusion of AI and Web3 technologies, while also embodying the distinctive artistic essence. The total quantity of 9,550 stands as an essential component of the NFT art digital collection within the Rune Protocol ecosystem and Bitcoin Network.";
    let data2 =
        "The MetaDukes NFT collection boasts a staggering 10000 unique PFP characters on Ethereum and BASE Chain. What sets this collection apart is its inspiration drawn from 11 world-renowned figures. Each character encapsulates the essence of these figures, presenting them in a light-hearted and satirical manner. They will also function as access passes to the Funs.AI SocialFi platform, offering holders a unique blend of utility and aesthetics.";
    return (
        <div
            id="compliance"
            className="relative bg-background-radial-gradient pb-16 h-[100vh]  md:min-h-[900px] max-w-[100vw] overflow-hidden"
        >
            {/* <StickyBar /> */}
            {/* <img src="/assets/clouds/cloud1.svg" alt="" className="animate-cloud_slide" />
            <img src="/assets/clouds/cloud2.svg" alt="" className="pt-[vh] animate-cloud_slide_130" />
            <div className="flex gap-x-32 animate-cloud_slide__70">
            <img src="/assets/clouds/cloud3.svg" alt="" className="pt-[5vh] " />
            <img src="/assets/clouds/cloud3.svg" alt="" className="pt-[5vh] " />
            </div>
        <img src="/assets/clouds/cloud4.svg" alt="" className="pt-[9vh] animate-cloud_slide__30" /> */}
            {/* 
            <img src="/assets/clouds/cloud1.svg" alt="" className="" />
            <img src="/assets/clouds/cloud2.svg" alt="" className="pt-[vh] " />
            <div className="flex gap-x-32 __70">
            <img src="/assets/clouds/cloud3.svg" alt="" className="pt-[5vh] " />
            <img src="/assets/clouds/cloud3.svg" alt="" className="pt-[5vh] " />
            </div>
        <img src="/assets/clouds/cloud4.svg" alt="" className="pt-[9vh] " /> */}

            <img className="h-[90vh] pt-16" src="/assets/clouds/clouds-group.png" alt="cloudsImage" />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[10%] right-[10%]  drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[45%] left-[15%] drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[85%] left-[5%] drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />
            <img
                src="/assets/stars.svg"
                alt=""
                className="absolute top-[75%] right-[5%] drop-shadow-[4px_4px_4px_rgb(255,255,255)]"
            />

            <div className="absolute inset-0 pt-16">
                <h1 className=" mt-6 font-extrabold text-center text-[#E9FF00] text-28-56 text-stroke-3 drop-shadow-[2px_2px_0_rgb(255,255,255)] md:drop-shadow-[4px_4px_0_rgb(255,255,255)]">
                    Compliance Strategy
                </h1>
                <h2 className="text-center mt-4 font-bold text-20-40 text-white">
                    Securing the Future of Crypto Innovation
                </h2>

                <div className="flex flex-col md:flex-row gap-y-4  md:gap-x-10 mx-auto w-fit mt-6">
                <div className=" md:flex-1 flex justify-center items-center">
                        <div className=" md:w-[85%] text-center md:text-start">

                            <p className="font-medium text-white text-12-20 mt-2 md:mt-5">
                                <span className="font-bold text-[#d4b8d7ff]">Security Token Offering (STO)</span> <br/>KNOWP tokens are issued via Security Token Offering (STO) to meet SEC regulations, ensuring legality and transparency.
                                <br/>

                                <span className="font-bold text-[#d4b8d7ff] ">Investor Protection</span> <br/>Compliance with investor requirements and fund custody through regulated exchanges ensure safety and project stability.
                                <br/>

                                <span className="font-bold text-[#d4b8d7ff] ">Tokenomics Design</span> <br/>Collaboration with legal expert and exchange ensures KNOWP token meets securities laws while supporting platform functionalities.
                                <br/>

                                <span className="font-bold text-[#d4b8d7ff] ">FIT21 and Global Compliance</span> <br/>Adherence to the FIT21 Act and global regulations ensures compliance in areas like decentralization and consumer protection.
                                <br/>

                                <span className="font-bold text-[#d4b8d7ff] ">KYC/AML</span> <br/>Implementation of KYC and AML measures tailored to regional laws to maintain global compliance and mitigate risks.
                                <br/>

                                <span className="font-bold text-[#d4b8d7ff] ">Decentralized Governance</span> <br/>DAO structure empowers users in governance, complemented by regular compliance education to enhance awareness and participation.
                                <br/>
                                <br/>
                                <a
                href="https://medium.com/@funs-ai"
                target="_blank"
                className={`font-kanit font-medium text-14-16 text-black  py-1 md:py-2 px-3 md:px-5 bg-[#E9FF00] rounded-full`}
            >
                More Details
            </a>

                            </p>

                            

                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute top-[10%] -right-[10%] w-[225px] md:w-[455px] h-[225px] md:h-[455px] bg-[#d722dade] rounded-full blur-[247px]" />
        </div>
    );
};

export default Compliance;
